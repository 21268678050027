// src/components/AuthLoginCallback.js
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {setUserInfoToSession} from "../Services/session.mgmt";
import axios from "axios";
import authService from "../Services/AuthService";
import Unauthorized from "../Pages/Unauthorized/Unauthorized";

const AuthLoginCallback = () => {
    const navigate = useNavigate();
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [unAuthorized, setUnAuthorized] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_GATEWAY_URL}/auth/userInfo`, {
            withCredentials: true
        })
            .then(response => {
                const userInfo = {
                    userid: response.data.accessToken.userid,
                    countryCd: response.data.accessToken.countryCd,
                    sitecode: response.data.accessToken.sitecode,
                };
                Promise.all([
                    authService.isExternalUser(userInfo.sitecode),
                    authService.isAdminUser(userInfo.userid),
                    authService.getUserDetail(userInfo.userid)
                ]).then(values => {
                    debugger
                    userInfo['isExternal'] = !!values[0].data;
                    userInfo['isAdmin'] = !!values[1].data;
                    userInfo['hasContractProfile'] = values[2].data.length > 0;
                    userInfo['cntrctrNb'] = values[2]?.data[0]?.cntrctrNb;
                    setUserInfoToSession(userInfo);
                    if (userInfo.isAdmin) {
                        navigate('/userVerification');
                    } else if (userInfo.isExternal && userInfo.hasContractProfile) {
                        navigate('/misc');
                    } else {
                        setUnAuthorized(true);
                    }
                    setLoadingUserInfo(false);
                }).catch(reason => {
                    console.log("AuthLoginCallback.retrieveUserInfo Failed: " + reason.toString());
                }).finally(() => {
                    console.log("AuthLoginCallback.retrieveUserInfo Completed with : " + JSON.stringify(userInfo));
                });
            });
    }, [navigate]);

    if(loadingUserInfo) return <>loading...</>;

    if(unAuthorized) return <Unauthorized/>;
    return (
        <div>
            <h2>Get User Token from Server...</h2>
        </div>
    );
};

export default AuthLoginCallback;
