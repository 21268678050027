
import React, { useState } from 'react';
import Header from "../../Components/Header/Header";
import "./Misc.css"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import {AssignmentService} from "../../Services/AssignmentService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";

function Misc() {

  const [companyName, setCompanyName] = useState("Ford Motor Credit Company")
  const [companyPlace, setCompanyPlace] = useState("Collections Center of Excellence (COE)")
  const [companyZipCode, setCompanyZipCode] = useState("PO Box 64400")
  const [cityPlace, setCityPlace] = useState("Colorado Springs, CO 80962-4400")

  const [companyMAddress, setCompanyAddress] = useState("2014 smoke FORD FOCUS SDR")
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState("Phone: 866-832-4720")
  const [companyMaiID, setCompanyMailID] = useState("E-Mail: webrepo@ford.com")
  const userInfo = getUserInfoFromSession();
  let navigate = useNavigate();


  const routeChange =  async () => {
    try {
      const repoAssignmentStatusInfo = await AssignmentService.getRepoAssignmentStatusInfoForMisc(userInfo.cntrctrNb);

      console.log(repoAssignmentStatusInfo.data)
      if (repoAssignmentStatusInfo.data) {
        let arrayresponse = repoAssignmentStatusInfo.data;

        arrayresponse.forEach((item) => {
          if (item.csrActnCd === 'A') {
            navigate(`/NewAssignment/${item.teamWrkBpiNb}`);
          } else if (item.csrActnCd === 'H') {
            navigate(`/holdAssignment/${item.teamWrkBpiNb}`);
          } else if (item.csrActnCd === 'C') {
            navigate(`/CancelAssignment/${item.teamWrkBpiNb}`);
          } else {
            navigate(`/outstandingAssignment`);
          }
        });
      }else{
        navigate(`/outstandingAssignment`);
      }

    } catch (err) {
      console.log(`Error on getting AssignmentStatus info for misc : ${err}`)
    }
  }

  return (

      <div>
        <Header value="none" />

        <div className="details_cntr">
          <div className='details'>
            <h2>Welcome to the Ford Credit</h2>
            <br />
            <h2> Repossession Contractor Website </h2>
          </div>
          <br />



          <div class="fds-layout-grid fmc-pa-3" className='dividerdiv'>
            <div class="fds-layout-grid__inner">
              <div
                  class="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom
    "
              >
                {/* <h3 class="fmc-type--heading3">Related Content</h3> */}
                <div className='details'> <span>Whom to Contact... </span>  <br />

                  <span>{companyName} </span>  <br />
                  <span>{companyPlace} </span>  <br />
                  <span>{companyZipCode} </span>  <br />
                  <span>{cityPlace}</span>  <br />
                  <span>{companyPhoneNumber} </span>  <br />

                  <span>{companyMaiID}</span>  <br />
                </div>
                <div className='concur_contain fds-weight--bold'>

                </div>
              </div>
              <div
                  class="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md
    "
              >
                <div className='details'> <span className='termcond '>By clicking on the "I Concur" button below, I agree to the following: </span>  <br /> </div>
                <div className="para">
              <span>
                For every vehicle repossessed based on an assignment through the Ford Credit Repossession Contractor Web Page, I will provide Ford Credit with a Vehicle Condition Report, Invoice and Personal Property Report. These documents will be submitted through the Ford Credit Repossession Contractor Web Page, within 12 hours of the vehicle being recovered.
              </span>
                </div>

                <div>
                  <div className='para'>
                    <ul class="fmc-list" >
                      <li>	Vehicle Condition Report</li>
                      <li>	Invoice</li>
                      <li>	Property Report</li>
                    </ul>
                  </div>
                </div>


                <div className='para'>

              <span> I will complete the <span className='tranining'><Link to="https://repoweb-pit.apps.pp01i.edc1.cf.ford.com/training/training.htm">online training</Link>  </span>before I use this Web Page to receive repossession assignments.


                All information viewed and/or submitted via this website is subject to the terms and conditions of your Independent Contractor Agreement - Repossession and your Supplier Privacy Assessment with Ford Motor Credit Company LLC, Ford Credit Canada Company or Canadian Road Leasing Company.
              </span>

                </div>
                <div className='details'><fmc-button label="I CONCUR" outlined onClick={routeChange}></fmc-button></div>
                <Footer/>
              </div>
            </div>
          </div>

        </div>
      </div>
  );

}
export default Misc;