import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import VCRsummary from "./VCRsummary";
import PersonalProprtySum from './PersonalProprtySum';
import Invoicesummary from "./Invoicesummary";
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import swal from "sweetalert";
import { AssignmentService } from "../../Services/AssignmentService";
import './Summary.css';
import {postCall} from "../../Services/CommonService";
import {getUserInfoFromSession} from "../../Services/session.mgmt";

export const showAlert = (message, icon) => swal('', message, icon);

function Outstandingsummary() {
    const { teamWrkBpiNb } = useParams();
    const [vcrData, setVcrData] = useState({});
    const [vehLocData, setVehLocData] = useState({});
    const [invoiceTypes, setInvoiceTypes] = useState([]);
    const [groundingData, setGroundingData] = useState({});
    const [invoiceData, setInvoiceData] = useState([]);
    const [ppData, setPpData] = useState([]);
    const [ppItems, setppItems] = useState([]);
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState({});
    const [assignmentDetails, setAddignmentDetails] = useState({});
    const [err, setError] = useState('');
    const [matchedEntries, setMatchedEntries] = useState([]);
    const [invoicetotal , setInvoiceTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const userInfo = getUserInfoFromSession()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    VCRResponse,
                    VehLocResponse,
                    GroundingResponse,
                    InvoiceResponse,
                    InvoiceTypesResponse,
                    PPropertyResponse,
                    ppItemsResponse,
                    AssignmentDetailsResponse
                ] = await Promise.all([
                    AssignmentService.getVcrDetails(teamWrkBpiNb),
                    AssignmentService.getVehLocDetails(teamWrkBpiNb),
                    AssignmentService.getGroundingInfo(teamWrkBpiNb),
                    AssignmentService.getInvoiceDetails(teamWrkBpiNb),
                    AssignmentService.getInvoiceTypes(1),
                    AssignmentService.getDbPropertyLocStat(teamWrkBpiNb),
                    AssignmentService.getPpDetails(teamWrkBpiNb),
                    AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb)
                ]);

                setVcrData(VCRResponse.data);
                console.log(VCRResponse.data);
                setVehLocData({
                    vehlocTypCd: GroundingResponse?.data?.vehLocTypCd ?? "",
                    auctCd: GroundingResponse?.data?.auctLocCd ?? "",
                    auctDs: GroundingResponse?.data?.auctLocNm ?? "",
                    teamWrkBpiNb: teamWrkBpiNb,
                    addrLine1Tx: VehLocResponse?.data?.addrLine1Tx ?? "",
                    addrLine2Tx: VehLocResponse?.data?.addrLine2Tx ?? "",
                    cityNm: VehLocResponse?.data?.cityNm ?? "",
                    stAbbrCd: VehLocResponse?.data?.stAbbrCd ?? "",
                    zipCd: VehLocResponse?.data?.zipCd ?? "",
                    phnCityAreaCd: VehLocResponse?.data?.phnCityAreaCd ?? "",
                    phnNb: VehLocResponse?.data?.phnNb ?? "",
                    lstUpdtUserId: GroundingResponse?.data?.lstUpdtUserId ?? "",
                    vehLocNm: VehLocResponse?.data?.vehLocNm ?? "",
                });
                setGroundingData(GroundingResponse.data);
                setInvoiceData(InvoiceResponse.data);
                setInvoiceTypes(Object.keys(InvoiceTypesResponse.data).map(key => ({
                    key: Number(key),
                    value: InvoiceTypesResponse.data[key]
                })));
                setPpData(PPropertyResponse.data);
                setppItems(ppItemsResponse.data);
                setAddignmentDetails(AssignmentDetailsResponse.data)
            } catch (err) {
               console.log("Error on Fectching Outstanding Summary Details" + err.response)
            }finally {
                setLoading(false)
            }
        };

        fetchData();
    }, [teamWrkBpiNb]);

    useEffect(() => {
        if (assignmentDetails.length && assignmentDetails[0].vinSerialNb) {
            const fetchBuyerData = async () => {
                try {
                    const BuyerCoBuyerResponse = await AssignmentService.getBuyerCobuyerInfo(assignmentDetails[0].vinSerialNb);
                    //const BuyerCoBuyerResponse =  await AssignmentService.getBuyerCobuyerInfo( "1FTFW1E51MFC15439");
                    console.log(BuyerCoBuyerResponse.data)
                    setBuyerCoBuyerInfo({
                        receivableAccountNumber: BuyerCoBuyerResponse.data[0].customerAccount.account.receivableAccountNumber,
                        vehicleIdentificationNumber: BuyerCoBuyerResponse.data[0].customerAccount.account.vehicleIdentificationNumber,
                        vehicleMake: BuyerCoBuyerResponse.data[0].customerAccount.account.vehicleMake,
                        vehicleModel: BuyerCoBuyerResponse.data[0].customerAccount.account.vehicleModel,
                        vehicleYear: BuyerCoBuyerResponse.data[0].customerAccount.account.vehicleYear,
                        firstName1: BuyerCoBuyerResponse.data[0].customerAccount.applicant.firstName1,
                        firstName2: BuyerCoBuyerResponse.data[0].customerAccount.applicant.firstName2,
                        firstName3: BuyerCoBuyerResponse.data[0].customerAccount.applicant.firstName3,
                        lastName1: BuyerCoBuyerResponse.data[0].customerAccount.applicant.lastName1,
                        lastName2: BuyerCoBuyerResponse.data[0].customerAccount.applicant.lastName2,
                    });
                } catch (err) {
                    console.log(`Error Fetching Buyer Co-Buyer Info: ${err}`);
                }
            };
            fetchBuyerData();
        }
    }, [assignmentDetails]);

    let totalAmount = 0;

    // Map invoice data to invoice types
    useEffect(() => {
        if (invoiceData.length && invoiceTypes.length) {
            const matched = invoiceData.map(invoice => {
                const matchingItem = invoiceTypes.find(item => item.key.toString() === invoice.cmpssEntryCd);
                totalAmount = totalAmount + invoice.cntrctrChgAm
                return matchingItem
                    ? {
                        cmpssEntryCd: invoice.cmpssEntryCd,
                        cntrctrChgAm: invoice.cntrctrChgAm,
                        cmpssEntryDs: matchingItem.value
                    }
                    : null;
            }).filter(Boolean);
            setMatchedEntries(matched);
            setInvoiceTotal(totalAmount);
            console.log(totalAmount)
        }
    }, [invoiceData, invoiceTypes]);

    const handleSumbit = () => {
        console.log("Vehicle grounded!");
        console.log("VCR Data:", JSON.stringify(vcrData));
        console.log("VehLoc Data:", JSON.stringify(vehLocData));
        console.log("Invoice Data:", JSON.stringify(matchedEntries));
        console.log("Invoice Total:", JSON.stringify(invoicetotal));
        console.log("PersonalProperty Data:", JSON.stringify(ppData));
        console.log("Personal Property Item:", JSON.stringify(ppItems));
        console.log("Buyer Info:", JSON.stringify(buyerCoBuyerInfo))
        postCall(
            "SqlMgr/insertCPkupLog?actnAm="+invoicetotal+"&actnCd=8&bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&userId="+userInfo.userid).then((response) => console.log(response.status));
        showAlert("Vehicle Grounded Successfully!", "success");
    };
    if (loading)
        return (
            <>
                <Header heading="" />
                <br />
                Loading...
            </>
        );
    return (
        <div>
            <br />
            <div className="center"><h2>Outstanding Assignments Summary</h2></div>
            <div className="details_cntr">
                <Header heading="" />
                <br />
                <AssignmentDetailsHeader bpiNo={teamWrkBpiNb} />
                <br />
                <fieldset disabled>
                    <VCRsummary vcrDetails={vcrData.vcr} vehLocData={vehLocData} buyerInfo={buyerCoBuyerInfo} />
                    <PersonalProprtySum ppDetails={ppData} ppItems={ppItems} />
                    <Invoicesummary invoiceData={matchedEntries} total = {invoicetotal}/>
                </fieldset>
                <div className="center" style={{ padding: '10px' }}>
                    <button type="submit" className="fmc-button" onClick={handleSumbit}>Submit</button>
                </div>
                <div className="fmc-bottom-banner__content center copy-rightbanner">
                    Copyright &copy; 2024 Ford Motor Credit Company. All Rights Reserved
                </div>
            </div>
        </div>
    );
}

export default Outstandingsummary;
