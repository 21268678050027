import serviceAxiosInstance from "../config/api.service.config";
import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

export function getVechileDisposalInfo(customerAccoutNumber,cobusCode,origBranch){
 
    return axios.get("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL']+cobusCode+"/"+origBranch+"/"+customerAccoutNumber);
      //return axios.get("https://localhost:3030/RepoWebFCNA/"+"api/v1/vehicleDisposal/7201/AC/356744684");
}
export function CreateVehicleDisposalInfo(insertvehicladisposaldata){
  
  return axios.post("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL'],insertvehicladisposaldata);
    
}
export function UpdateVehicleDisposalInfo(customerAccoutNumber,cobusCode,origBranch,updatevehicleDisposaldata1){
  
  return axios.put("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['VECHICLE_DISPOSAL_INFO_BASE_URL']+cobusCode+"/"+origBranch+"/"+customerAccoutNumber,updatevehicleDisposaldata1);
    
}


export function getAuctionLocationList(countrycode){
  
  return axios.get("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['GET_AUCTION_LOCATION']+countrycode);
    
}

export function postGivUpload(fileByte,CscmRequestObj){
  
  return axios.get("http://localhost:3030/RepoWebCSCM/"+ENDPOINTS['CSCM_URL']);
    
}

export function FcnaDB2Postcall(transactionObj){
  
  return axios.post("https://localhost:3030/RepoWebFCNA/"+ENDPOINTS['POST_FCNADB2'],transactionObj);
    
}



//export function postGivUpload(BPMWebServiceObj){
  
 // return axios.get("http://localhost:3030/RepoWebCSCM/"+ENDPOINTS['CSCM_URL']);
    
//}


