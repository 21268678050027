import Header from "../../Components/Header/Header";
import React, {useEffect, useState} from "react";
import "./NewAssignment.css"
import {getCall, postCall, postCallBPM, postCallPinnacle, putCall} from "../../Services/CommonService";
import {useNavigate, useParams} from "react-router-dom";
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import VehicleConditionReport from "../VehicleConditionReport/VehicleConditionReport";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import * as Constants from "../../constants/RepoReactConstants";
import { PINNCALE_LOG_OBJ, VEHICLE_DISPOSAL_OBJ } from "../../constants/commonJsonConstants";

import { CommonDataService, fetchJsonObject ,commentStr,convertDateToISOString,getCurrentTimestampInISOFormat} from "../../Services/CommonDataService";
import {FcnaDB2Postcall} from "../../api/accountsummary.api";
import {AssignmentService} from "../../Services/AssignmentService";
function NewAssignment(props){

  const currntenv ="DEV";
  const [loading, setLoading] = useState(true);
  const [accountInformation, setAccountInformation] = useState([]);
  const [commonRepoInformation, setCommonRepoInformation] = useState([]);
  const [contractorInfoForComment, setContractorInfoForComment] = useState([]);

  const navigate = useNavigate();
  let { teamWrkBpiNb } = useParams();
  console.log(teamWrkBpiNb)

  const [assignmentDetails, setAssignmentDetails] = useState([])
  const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
  const userInfo = getUserInfoFromSession()
  const VinNumber = assignmentDetails.vinSerialNb


  function getCurrentTimestamp() {
    const now = new Date();


    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');


    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  useEffect(async () => {

    const fetchcommonInfo = async () => {


      try {
        // To retrieve   account information and commonRepo Information and  contractorcontact information
        CommonDataService.getAccountInfo(teamWrkBpiNb, userInfo.cntrctrNb).then(r => setAccountInformation(r.data));
        CommonDataService.getRepoCommonInfo(teamWrkBpiNb, userInfo.cntrctrNb).then(r => setCommonRepoInformation(r.data));
        CommonDataService.getCntrctrInfoForComments(userInfo.userid).then(r => setContractorInfoForComment(r.data));
        setLoading(false);


      } catch (error) {
        // Handle any errors that occur in any of the promises
        console.error('An error occurred:', error);
      } finally {
        //setLoading(false);
      }
    }
    fetchcommonInfo();

    const assignmentResponse = await AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb)
    setAssignmentDetails(assignmentResponse.data)
    AssignmentService.getBuyerCobuyerInfo(VinNumber).then(response => {
      setBuyerCoBuyerInfo(response.data)
    })

    //for local testing//
    // setBuyerCoBuyerInfo([
    //   {
    //     "customerAccount": {
    //       "account": {
    //         "receivableAccountNumber": 54957040,
    //         "companyBusinessCode": 1,
    //         "contractType": "Ret",
    //         "accountStatus": "ACTIVE ACCOUNT",
    //         "vehicleYear": "2016",
    //         "vehicleMake": "FORD",
    //         "vehicleModel": "F150",
    //         "vehicleIdentificationNumber": "1FTEW1EPXGKE25305"
    //       },
    //       "applicant": {
    //         "applicantCode": "PR",
    //         "firstName1": "MELANIE",
    //         "firstName2": "J",
    //         "firstName3": null,
    //         "lastName1": "MEZA",
    //         "lastName2": null,
    //         "title": null,
    //         "generation": null,
    //         "businessName": null,
    //         "address": {
    //           "addressLine1": "5408 OLIVINE CT",
    //           "addressLine2": null,
    //           "city": "LAS VEGAS",
    //           "stateCode": "NV",
    //           "otherCountry": null,
    //           "otherPostCode": null,
    //           "otherState": null,
    //           "postCode": "89130-1990",
    //           "country": "USA"
    //         }
    //       }
    //     }
    //   }
    // ])


  }, []);

  const  logPinnacle = (accountInformation,commonRepoInformation,contractorInfoForComment,PINNCALE_LOG_OBJ) =>
  {


    PINNCALE_LOG_OBJ.receivableAccountNumber = accountInformation.custAcctNb;
    PINNCALE_LOG_OBJ.coBusCd = accountInformation.coBusCd;
    PINNCALE_LOG_OBJ.createdTimeStamp = getCurrentTimestamp();
    PINNCALE_LOG_OBJ.enteredBranchCode = accountInformation.superBranchCd;
    console.log(" PINNCALE  LOG Object to Log before add comment :::::::" , PINNCALE_LOG_OBJ) ;

    console.log("comment String with pinnalce:::::::::::::::::::",commentStr(contractorInfoForComment,Constants.ACTION_GROUNDING,Constants.rsGROUNDED,userInfo.userid));
    PINNCALE_LOG_OBJ.comment=commentStr(contractorInfoForComment,Constants.ACTION_GROUNDING,Constants.rsGROUNDED,userInfo.userid);
    console.log(" after comment set:::::::::::::::::::",PINNCALE_LOG_OBJ);


    if(currntenv!='DEV'){
      CommonDataService.createPinnacleContact(PINNCALE_LOG_OBJ).then(r => {
        if (r.status == 204) {
          console.log("pinnacle Response " + "no data");
        }

      });


    }
    else{
      console.log("Pinncale Log Object in DEV "+  JSON.stringify(PINNCALE_LOG_OBJ) );
    }


  }



  function Accept() {

    logPinnacle(accountInformation,commonRepoInformation,contractorInfoForComment,PINNCALE_LOG_OBJ);
    putCall(
        "processQuestion/updateCntrctAcct?bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&sConfirm=Y&sCsrAction=A").then((response) => console.log(response));
    postCall(
        "SqlMgr/insertCPkupLog?actnAm=0.0&actnCd=3&bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&userId="+userInfo.userid).then((response) => console.log(response.status));

    FcnaDB2Postcall({
      "acctNb": userInfo.cntrctrNb,
      "coBusCd": 1,
      "errorCode": 0,
      "lastUpdatedUserIdentificationCode": userInfo.cntrctrNb,
      "sourceSystemCode": 12,
      "statusCode": 0,
      "transTypeCd": 21,
      "transactionDataText": teamWrkBpiNb+";"+"ACCEPT",
      "isImmediate":true
    }).then((response) => console.log(response));

    navigate('/outstandingAssignment');

  }

  function Decline() {
    putCall(
        "processQuestion/updateCntrctAcct?bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&sConfirm=Y&sCsrAction=R").then((response) => console.log(response));
    postCall(
        "SqlMgr/insertCPkupLog?actnAm=11111&actnCd=4&bpiNb="+teamWrkBpiNb+"&cntrctrNb="+userInfo.cntrctrNb+"&userId="+userInfo.user,

    ).then((response) => console.log(response.status));


  }

  return(
      <>
        <Header heading="New Assignment" />
        <br/>
        <AssignmentDetailsHeader bpiNo = {teamWrkBpiNb}/>
        <br/>
        <div>
          {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
            let applicant = buyerCoBuyerInfo.customerAccount.applicant
            return (
                <section
                    className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                  <div className="fds-weight--bold">Buyer Information:</div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                      <div className="fds-layout-grid__cell--span-6">Employer: </div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">Phone: </div>
                      <div className="fds-layout-grid__cell--span-6">Phone: </div>
                    </div>
                  </div>
                </section>
            );

          })}
          {buyerCoBuyerInfo.map((buyerCoBuyerInfo)=> {
            if(buyerCoBuyerInfo.customerAccount.applicant.applicantCode === "PR"){
              return null
            }else{
              let applicant = buyerCoBuyerInfo.customerAccount.applicant
              return (
                  <section
                      className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                  >
                    <div className="fds-weight--bold">Co-Buyer Information:</div>
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                      </div>
                    </div>
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                        <div className="fds-layout-grid__cell--span-6">Employer: </div>
                      </div>
                    </div>
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                        <div className="fds-layout-grid__cell--span-6">Phone: </div>
                      </div>
                    </div>
                  </section>
              );
            }

          })}
          {assignmentDetails.map((assignmentDetails) => {
            return (
                <section
                    className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                  <div className="fds-weight--bold">Assignment Information:</div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">Type of surrender: {assignmentDetails.recvrMthdCdDs}</div>
                      <div className="fds-layout-grid__cell--span-6">Fee: {assignmentDetails.acptCntrctrFeeAm}</div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">CSR Name: </div>
                      <div className="fds-layout-grid__cell--span-6">Phone: </div>
                    </div>
                  </div>
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-6">Special Instructions /
                        Directions: </div>
                    </div>
                  </div>
                </section>
            );

          })}
          <br/>
          <section>
            <div className="center">
              <fmc-button label="Accept" onClick={Accept}></fmc-button>
              <fmc-button label="Decline" onClick={Decline}></fmc-button>
            </div>
          </section>
        </div>

      </>

  )
}

export default NewAssignment;