import React, { useEffect } from 'react';
import './Summary.css';
import { getPPropertyInfoFromSession } from "../../Services/session.mgmt";

function Invoicesummary({ invoiceData , total}) {

  useEffect(() => {
    console.log("invoiceData changed:", invoiceData);
  }, [invoiceData]);

  if (!invoiceData) {
    // Data is not available yet
    return <div>Loading...</div>; // Or handle as needed
  }

  return (
      <div id='details_tab_cntr'>
        <div className='details_cntr'>
          <div id='invoiceheader' className='center'>
            INVOICE
          </div>

          <div className='invoicepart'>
            <section className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white">
              <div className='center'>
                <table className="fmc-table">
                  <thead>
                  <tr>
                    <th>Description</th>
                    <th>Amount</th>
                  </tr>
                  </thead>
                  <tbody>
                  {invoiceData.map((invoice, index) => (
                      <tr key={index}>
                        <td>{invoice.cmpssEntryDs ?? "N/A"}</td>
                        <td>${invoice.cntrctrChgAm ?? "0.00"}</td>
                      </tr>
                  ))}
                  <tr>
                    <td style={{ fontWeight: 'bold' }}>Total</td>
                    <td style={{ fontWeight: 'bold' }}>${total}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
  );
}

export default Invoicesummary;