import {ENDPOINTS} from "../constants/endpoints";
import axios from "axios";

export function getAccountInfo(teamworkBpi,cntrctrNb){
 
    return axios.get(process.env.REACT_APP_SERVICE_BASE_URL+"api/v1/"+ENDPOINTS['GET_ACCOUNT_INFO']+teamworkBpi+"/"+cntrctrNb);
}

export function getRepoCommonInfo(teamworkBpi,cntrctrNb){
 
  return axios.get(process.env.REACT_APP_SERVICE_BASE_URL+"api/v1/"+ENDPOINTS['GET_REPOCOMMON_INFO']+teamworkBpi+"/"+cntrctrNb);
}

export  function  getCntrctrInfoForComments(userId){

  return axios.get(process.env.REACT_APP_SERVICE_BASE_URL+"api/v1/"+ENDPOINTS['GET_CONTRACTOR_INFO_FOR_COMMENT']+userId);
}

export function createPinnacleContact(pinncalelogobj){
  
  return axios.post(process.env.REACT_APP_SERVICE_BASE_URL_ACT+ENDPOINTS['POST_PINNCAL_LOG'],pinncalelogobj);
    
}