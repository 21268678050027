import React, {useEffect, useState} from "react";
import "./AssignmentDetails.css"
import AssignmentDetailsHeader from "../common/AssignmentDetailsHeader";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {AssignmentService} from "../../Services/AssignmentService";
import Header from "../../Components/Header/Header";
import _ from 'lodash';

function AssignmentDetails({ bpiNo, buyerInfo, onContinue }){

    const teamWrkBpiNb= bpiNo
    console.log(teamWrkBpiNb)
    console.log(buyerInfo)

    const [assignmentDetails, setAssignmentDetails] = useState([])
    const [buyerCoBuyerInfo, setBuyerCoBuyerInfo] = useState([])
    const [loading, setLoading] = useState(true);
    const userInfo = getUserInfoFromSession()
    // const VinNumber = "1FTFW1E51MFC15439"

    useEffect(() => {
        const fetchData = async ()=>{
            try{

               const assignmentResponse =await  AssignmentService.getAssignmentInfo(teamWrkBpiNb, userInfo.cntrctrNb)
                setAssignmentDetails(assignmentResponse.data)
               setBuyerCoBuyerInfo(buyerInfo)
            }catch (err){
              console.log(`Error FectchingData : ${err}`)
            }finally {
                setLoading(false)
            }
        };
        fetchData();

    }, [teamWrkBpiNb , buyerInfo]);

    if (loading && buyerInfo.length <0)
        return (
            <>
                <AssignmentDetailsHeader bpiNo = {teamWrkBpiNb}/>
                <br />
                Loading...
            </>
        );
    return(
        <>
        <AssignmentDetailsHeader bpiNo={teamWrkBpiNb}/>
        <br/>
        <div>
            {buyerCoBuyerInfo.map((buyerCoBuyerInfo, index) => {
                let applicant = buyerCoBuyerInfo.customerAccount.applicant;
                const {address, firstName1, firstName2, firstName3, phone} = applicant;

                // Ensure that all parts of the name and address exist before rendering
                const fullName = `${firstName1} ${firstName2} ${firstName3}`;

                // Handle missing fields gracefully (e.g., phone or address)
                const fullAddress = address
                    ? `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.stateCode} ${address.otherCountry} ${address.otherPostCode} ${address.otherState} ${address.postCode} ${address.country}`
                    : 'Address not available';

                const phoneNumbers = phone
                    ? `${phone.number1} ${phone.number2}` // Assuming phone is an object with number1 and number2 properties
                    : 'Phone numbers not available';

                return (
                    <section
                        key={index} // Use index or a unique id from your data instead of concatenated names
                        className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                    >
                        <div className="fds-weight--bold">Buyer Information:</div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0">
                                <div className="fds-layout-grid__cell--span-12">
                                    Name: {fullName}
                                </div>
                            </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0">
                                <div className="fds-layout-grid__cell--span-6">
                                    Address: {fullAddress}
                                </div>
                                <div className="fds-layout-grid__cell--span-6">Employer:</div>
                            </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0">
                                <div className="fds-layout-grid__cell--span-6">Phone: {phoneNumbers}</div>
                                <div className="fds-layout-grid__cell--span-6">Phone:</div>
                            </div>
                        </div>
                    </section>
                );
            })}
        {buyerCoBuyerInfo.map((buyerCoBuyerInfo) => {
            if (buyerCoBuyerInfo.customerAccount.applicant.applicantCode === "PR") {
                return null
            } else {
                let applicant = buyerCoBuyerInfo.customerAccount.applicant
                return (
                    <section key={applicant.firstName1 + '-' + applicant.firstName2 + '-' + applicant.firstName3}
                             className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                    >
                        <div className="fds-weight--bold">Co-Buyer Information:</div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                                <div
                                    className="fds-layout-grid__cell--span-12">Name: {applicant.firstName1} {applicant.firstName2} {applicant.firstName3}</div>
                            </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                                <div
                                    className="fds-layout-grid__cell--span-6">Address: {applicant.address.addressLine1} {applicant.address.addressLine2} {applicant.address.city} {applicant.address.stateCode} {applicant.address.otherCountry} {applicant.address.otherPostCode} {applicant.address.otherState} {applicant.address.postCode} {applicant.address.country}</div>
                                <div className="fds-layout-grid__cell--span-6">Employer:</div>
                            </div>
                        </div>
                        <div className="fds-layout-grid p-0">
                            <div className="fds-layout-grid__inner p-0 ">
                                <div className="fds-layout-grid__cell--span-6">Phone:</div>
                                <div className="fds-layout-grid__cell--span-6">Phone:</div>
                            </div>
                        </div>
                    </section>
                );
            }

        })}
        {_.map(assignmentDetails, (assignmentDetails) => (
                <section key={assignmentDetails.recvrMthdCdDs}
                         className="fmc-global-attribute-bar fmc-global-attribute-bar--vr-md fmc-px-3 fmc-pb-3-sm fmc-py-3-md fmc-py-3-lg fmc-py-3-xl fds-color__bg--white"
                >
                    <div className="fds-weight--bold">Assignment Information:</div>
                    <div className="fds-layout-grid p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">Type of
                                surrender: {assignmentDetails.recvrMthdCdDs}</div>
                            <div
                                className="fds-layout-grid__cell--span-6">Fee: {assignmentDetails.acptCntrctrFeeAm}</div>
                        </div>
                    </div>
                    <div className="fds-layout-grid p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">CSR Name:</div>
                            <div className="fds-layout-grid__cell--span-6">Phone:</div>
                        </div>
                    </div>
                    <div className="fds-layout-grid p-0">
                        <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">Special Instructions /
                                Directions:
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        <br/>
        <section>
            <div className="center">
                <button type="button" className="fmc-button" onClick={onContinue}>Continue</button>
            </div>
        </section>
        </div>

</>

)
}

export default AssignmentDetails;