import "./Profile.css";
import React, {useEffect, useState} from "react";
import Header from "../../Components/Header/Header";
import {getUserInfoFromSession} from "../../Services/session.mgmt";
import {getCall, putCall} from "../../Services/CommonService";
import '@ford/ford-design-system/dist/web-components.js';

function Profile() {
  const [contractorName, setcontractorName] = useState("");
  const [addressline1, setaddressline1] = useState("");
  const [address, setAddress] = useState("");
  const [addressCd, setaddressCd] = useState("");
  const [zipCode, setzipCode] = useState("");
  const [taxNb, settaxNb] = useState("");
  const [alertPreference, setalertPreference] = useState("");
  const [email, setemail] = useState("");

  const [primaryContact, setprimaryContact] = useState("");
  const [secondaryContact, setsecondaryContact] = useState("");

  const [other, setOther] = useState("");
  const [remainingChars, setRemainingChars] = useState(255);

  const [cityAreaCd, setcityAreaCd] = useState("");
  const [phone, setPhone] = useState("");
  const [cityAreaCd1, setcityAreaCd1] = useState("");
  const [phone1, setPhone1] = useState("");
  const [cityAreaCd2, setcityAreaCd2] = useState("");
  const [phone2, setPhone2] = useState("");
  const [cityAreaCd3, setcityAreaCd3] = useState("");
  const [mobilePhone, setmobilePhone] = useState("");
  const [cityAreaCd4, setcityAreaCd4] = useState("");
  const [primaryTextPhone, setprimaryTextPhone] = useState("");

  //Price Structure variables
  const [priceStctText, setpriceStctText] = useState("");
  const [selfHelpAmount, setselfHelpAmount] = useState("");
  const [valuntaryFeeAmount, setvaluntaryFeeAmount] = useState("");
  const [dealerFeeAmount, setdealerFeeAmount] = useState("");
  const [closeFeeAmount, setcloseFeeAmount] = useState("");
  const [contingencyIndicator, setContingencyIndicator] = useState("");
  const [deliveryActionIndicator, setdeliveryActionIndicator] = useState("");
  const [gvwIndicator, setgvwIndicator] = useState("");
  const [pricingArray, setPricingArray] = useState([]);
  //Date Expiry Variables

  const [effectiveDate, seteffectiveDate] = useState("");
  const [expiryDate, setsetexpiryDate] = useState("");
  const [businessLcnExpiryDt, setbusinessLcnExpiryDt] = useState("");
  const [businessLcnText, setbusinessLcnText] = useState("");
  const [InsuranceInfoExpiryDt, setInsuranceInfoExpiryDt] = useState("");
  const [InsuranceInfo, setInsuranceInfo] = useState("");

  // price Stucrure Number variables
  const [PriceStructureNb, setPriceStructureNb] = useState("");
  const [coverageArray, setCoverageArray] = useState([]);
  //Get States
  const [statesArray, setstatesArray] = useState([]);
  const [stateAbbrCode1, setstateAbbrCode1] = useState("");

  const userInfo = getUserInfoFromSession()

  //Functions for Validations
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab", // Allow control keys
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9" // Allow numeric keys
    ];

    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const countCharacters = (e) => {
    const value = e.target.value;
    console.log(value); // Log the current input value
    setOther(value);
    setRemainingChars(255 - value.length);
  };

  
  useEffect(() => {
    getHttpResult();
    //getStates();
    getContact();
    getPriceStructure();
    getDateExpiry();
    getStateandCounty();
  }, other);
 // useEffect(() => {
    function getHttpResult() {
      const userId = userInfo.userid
      
      getCall("SqlMgr/getCntrctrProfile/"+userId).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          let arrayresponse = [];
          arrayresponse = response.data;
          setcontractorName(arrayresponse[0].cntrctrBusNm);
          setzipCode(arrayresponse[0].zipCd);
          settaxNb(arrayresponse[0].taxNb);
          setalertPreference(arrayresponse[0].alrtPrfrncCd);
          setemail(arrayresponse[0].emailAddrTx);

          setaddressline1(arrayresponse[0].addrLine1Tx);
          setAddress(arrayresponse[0].cityNm);
          setaddressCd(arrayresponse[0].stAbbrCd);
          setprimaryContact(arrayresponse[0].prmryCntctNm);
          setsecondaryContact(arrayresponse[0].scndryCntctNm);
          setRemainingChars(arrayresponse[0].othrInfoTx);

          return response;
        } else {
          throw new Error("Something went wrong /on api server!");
        }
      });

      // return axios(
      //   "http://localhost:8080/repowebservices/api/v1/SqlMgr/getCntrctrProfile/" +
      //     userId,
      //   {
      //     method: "GET",
      //     headers: myHeaders
      //   }
      // )
      //   .then((response) => {
      //     if (response.status === 200) {
      //       console.log(response.data);
      //       let arrayresponse = [];
      //       arrayresponse = response.data;
      //       setcontractorName(arrayresponse[0].cntrctrBusNm);
      //       setzipCode(arrayresponse[0].zipCd);
      //       settaxNb(arrayresponse[0].taxNb);
      //       setalertPreference(arrayresponse[0].alrtPrfrncCd);
      //       setemail(arrayresponse[0].emailAddrTx);

      //       setaddressline1(arrayresponse[0].addrLine1Tx);
      //       setAddress(arrayresponse[0].cityNm);
      //       setaddressCd(arrayresponse[0].stAbbrCd);
      //       setprimaryContact(arrayresponse[0].prmryCntctNm);
      //       setsecondaryContact(arrayresponse[0].scndryCntctNm);
      //       setRemainingChars(arrayresponse[0].othrInfoTx);

      //       return response;
      //     } else {
      //       throw new Error("Something went wrong /on api server!");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

    //getHttpResult().then((response) => response.data);
 // }, []);

  //get States

  //useEffect(() => {
    // function getStates() {
      

    //   getCall("contractorRequestManager/getStateCodes").then((response) => {
    //     if (response.status === 200) {
    //       console.log(response.data);
    //       let arrayresponse = [];
    //       arrayresponse = response.data;

    //       setstatesArray(arrayresponse);

    //       setstateAbbrCode1(arrayresponse[0].stateabbrCode);
    //       console.log(arrayresponse[0].stateabbrCode);
    //       //setCountyName(arrayresponse[0].cntyNm);

    //       return response;
    //     } else {
    //       throw new Error("Something went wrong /on api server!");
    //     }
    //   });
    //   // return axios(
    //   //   "http://localhost:8080/repowebservices/api/v1/contractorRequestManager/getStateCodes",
    //   //   {
    //   //     method: "GET",
    //   //     headers: myHeaders
    //   //   }
    //   // )
    //   //   .then((response) => {
    //   //     if (response.status === 200) {
    //   //       console.log(response.data);
    //   //       let arrayresponse = [];
    //   //       arrayresponse = response.data;

    //   //       setstatesArray(arrayresponse);

    //   //       setstateAbbrCode1(arrayresponse[0].stateabbrCode);
    //   //       console.log(arrayresponse[0].stateabbrCode);
    //   //       //setCountyName(arrayresponse[0].cntyNm);

    //   //       return response;
    //   //     } else {
    //   //       throw new Error("Something went wrong /on api server!");
    //   //     }
    //   //   })
    //   //   .catch((error) => {
    //   //     console.error(error);
    //   //   });
    // }

    //getStates().then((response) => response.data);
  //}, []);

 // useEffect(() => {
    function getContact() {
      const cntrctrNb = userInfo.cntrctrNb;
      

      getCall("profileMaint/getContractorContact/" +cntrctrNb).then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            let arrayresponse = [];
            arrayresponse = response.data;
            setcityAreaCd(arrayresponse[0]?.phnCityAreaCd);
            setPhone(arrayresponse[0]?.phnNb);
            setcityAreaCd1(arrayresponse[1]?.phnCityAreaCd);
            setPhone1(arrayresponse[1]?.phnNb);
            setcityAreaCd2(arrayresponse[2]?.phnCityAreaCd);
            setPhone2(arrayresponse[2]?.phnNb);
            setcityAreaCd3(arrayresponse[3]?.phnCityAreaCd);
            setmobilePhone(arrayresponse[3]?.phnNb);
            setcityAreaCd4(arrayresponse[4]?.phnCityAreaCd);
            setprimaryTextPhone(arrayresponse[4]?.phnNb);

            return response;
          } else {
            throw new Error("Something went wrong /on api server!");
          }
        });
        

      // return axios(
      //   "http://localhost:8080/repowebservices/api/v1/profileMaint/getContractorContact/" +
      //     cntrctrNb,
      //   {
      //     method: "GET",
      //     headers: myHeaders
      //   }
      // )
      //   .then((response) => {
      //     if (response.status === 200) {
      //       console.log(response.data);
      //       let arrayresponse = [];
      //       arrayresponse = response.data;
      //       setcityAreaCd(arrayresponse[0].phnCityAreaCd);
      //       setPhone(arrayresponse[0].phnNb);
      //       setcityAreaCd1(arrayresponse[1].phnCityAreaCd);
      //       setPhone1(arrayresponse[1].phnNb);
      //       setcityAreaCd2(arrayresponse[2].phnCityAreaCd);
      //       setPhone2(arrayresponse[2].phnNb);
      //       setcityAreaCd3(arrayresponse[3].phnCityAreaCd);
      //       setmobilePhone(arrayresponse[3].phnNb);
      //       setcityAreaCd4(arrayresponse[4].phnCityAreaCd);
      //       setprimaryTextPhone(arrayresponse[4].phnNb);

      //       return response;
      //     } else {
      //       throw new Error("Something went wrong /on api server!");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

    //getContact().then((response) => response.data);
  //}, []);

//  useEffect(() => {
    function getPriceStructure() {
      const cntrctrNb = userInfo.cntrctrNb;
     
      getCall("modifyContractorProfile/constructContractorFeeVector/" +cntrctrNb).then((response) => {
        if (response.status === 200) {
          //console.log(response.data.result.cntrctrFeeVector);
          let arrayresponse = [];
          arrayresponse = response.data.result.cntrctrFeeVector;
          setPricingArray(arrayresponse);
          console.log(arrayresponse);
          setselfHelpAmount(arrayresponse[0].feeAm);
          console.log(arrayresponse[0].feeAm);
          setpriceStctText(arrayresponse[0].prcStrctTx);
          setvaluntaryFeeAmount(arrayresponse[0].vlntrFeeAm);
          setdealerFeeAmount(arrayresponse[0].vlntrTodlrFeeAm);
          setcloseFeeAmount(arrayresponse[0].clsFeeAm);
          setContingencyIndicator(arrayresponse[0].cntngntIn);
          setdeliveryActionIndicator(arrayresponse[0].dlvryToAuctIn);
          setgvwIndicator(arrayresponse[0].gvwTypeCd);
          setPriceStructureNb(arrayresponse[0].prcStrctNb);
          console.log(arrayresponse[0].prcStrctNb);
        } else {
          throw new Error("Something went wrong /on api server!");
        }
      }).catch((error) => {
        console.error(error);
      });

      // return axios(
      //   "http://localhost:8080/repowebservices/api/v1/modifyContractorProfile/constructContractorFeeVector/" +
      //     cntrctrNb,
      //   {
      //     method: "GET",
      //     headers: myHeaders
      //   }
      // )
      //   .then()
        
    }
   // getPriceStructure().then((response) => response.data);
    
  //}, []);

  //Date Expiry Details BusinessLicence Info

 // useEffect(() => {
    function getDateExpiry() {


      getCall("modifyContractorProfile/getContractorPersonalDetails/6").then((response) => {
        if (response.status === 200) {
          console.log(response.data.result.cntrctrData2ApiList);
          let arrayresponse = [];
          arrayresponse = response.data.result.cntrctrData2ApiList;
          //console.log(arrayresponse)
          seteffectiveDate(arrayresponse[0].effDt);
          setsetexpiryDate(arrayresponse[0].expirDt);
          //console.log(arrayresponse[0].expirDt);
          setbusinessLcnExpiryDt(arrayresponse[0].busLcnsExpirDt);
          setbusinessLcnText(arrayresponse[0].busLcnsTx);
          setInsuranceInfoExpiryDt(arrayresponse[0].insInfoExpirDt);
          //setsetexpiryDate(setInsuranceInfo[0].expirDt);
        } else {
          throw new Error("Something went wrong /on api server!");
        }
      })
      // return axios(
      //   "http://localhost:8080/repowebservices/api/v1/modifyContractorProfile/getContractorPersonalDetails/6",
      //   {
      //     method: "GET",
      //     headers: myHeaders
      //   }
      // )
      //   .then((response) => {
      //     if (response.status === 200) {
      //       console.log(response.data.result.cntrctrData2ApiList);
      //       let arrayresponse = [];
      //       arrayresponse = response.data.result.cntrctrData2ApiList;
      //       //console.log(arrayresponse)
      //       seteffectiveDate(arrayresponse[0].effDt);
      //       setsetexpiryDate(arrayresponse[0].expirDt);
      //       //console.log(arrayresponse[0].expirDt);
      //       setbusinessLcnExpiryDt(arrayresponse[0].busLcnsExpirDt);
      //       setbusinessLcnText(arrayresponse[0].busLcnsTx);
      //       setInsuranceInfoExpiryDt(arrayresponse[0].insInfoExpirDt);
      //       //setsetexpiryDate(setInsuranceInfo[0].expirDt);
      //     } else {
      //       throw new Error("Something went wrong /on api server!");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

   // getDateExpiry().then((response) => response.data);
  //}, []);

  // Get State and counties for Coverage Area
  const [stateAbbrCode, setstateAbbrCode] = useState("");
  const [CountyName, setCountyName] = useState("");

  //useEffect(() => {
    function getStateandCounty() {
      const cntrctrNb = userInfo.cntrctrNb;

      getCall("viewCoverageArea/getCountiesData/" +cntrctrNb).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          let arrayresponse = [];
          arrayresponse = response.data;

          setCoverageArray(arrayresponse);

          setstateAbbrCode(arrayresponse[0].stAbbrCd);
          setCountyName(arrayresponse[0].cntyNm);
          //setsetexpiryDate(arrayresponse[0].expirDt);
          //console.log(arrayresponse[0].expirDt);
        } else {
          throw new Error("Something went wrong /on api server!");
        }
      })
      // return axios(
      //   "http://localhost:8080/repowebservices/api/v1/viewCoverageArea/getCountiesData/" +
      //     cntrctrNb,
      //   {
      //     method: "GET",
      //     headers: myHeaders
      //   }
      // )
      //   .then((response) => {
      //     if (response.status === 200) {
      //       console.log(response.data);
      //       let arrayresponse = [];
      //       arrayresponse = response.data;

      //       setCoverageArray(arrayresponse);

      //       setstateAbbrCode(arrayresponse[0].stAbbrCd);
      //       setCountyName(arrayresponse[0].cntyNm);
      //       //setsetexpiryDate(arrayresponse[0].expirDt);
      //       //console.log(arrayresponse[0].expirDt);
      //     } else {
      //       throw new Error("Something went wrong /on api server!");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    }

    //getStateandCounty().then((response) => response.data);
  //}, []);

  //Update Contractor Information event handling for update button

  function update() {
    const data = {
      addrLine1Tx: addressline1,
      alrtPrfrncCd: alertPreference,
      cityNm: address,
      emailAddrTx: email,
      gsdbCd: "m",
      stAbbrCd: addressCd,
      zipCd: zipCode,
      othrInfoTx: remainingChars,
      prmryCntctNm: primaryContact,
      rdnInd: 7,
      scndryCntctNm: secondaryContact
    };
    console.log(data);
    putCall("profileMaint/updateContractorData/apandi1", data).then(
      (response) => console.log(response)
    );
  }

  return (
    <>
      <Header heading="Profile" />
      <br />
      <div className="details_cntr">
        <img
          className="fmc-top-banner__demo-billboard"
          src={process.env.PUBLIC_URL + "/Assets/Profile.png"}
          alt=""
          height="100px"
          width="230px"
        />

        <div className="fds-layout-gri p-0">
          <div className="fds-layout-grid__inner p-0 ">
            <div className="fds-layout-grid__cell--span-6">
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Name:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    {contractorName}
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Address:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="field"
                      value={addressline1}
                      onInput={(e) => setaddressline1(e.target.value)}
                    ></fmc-input>
                    <fmc-input
                      name="field"
                      value={address}
                      onInput={(e) => setAddress(e.target.value)}
                    ></fmc-input>

                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-6">
                          <select
                            className="fmc-select"
                            slot="input"
                            name="select-field"
                            status="standard"
                            value={stateAbbrCode}
                            required
                            onChange={(e) => setstateAbbrCode(e.target.value)}
                          >
                            {statesArray &&
                              statesArray.map((item) => (
                                <>
                                  <option
                                    key={item.stateabbrCode}
                                    value={item.stateabbrCode}
                                  >
                                    {item.stateabbrCode}
                                  </option>
                                </>
                              ))}
                          </select>
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          <fmc-input
                            name="field"
                            value={zipCode}
                            onInput={(e) => setzipCode(e.target.value)}
                          ></fmc-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Fedrel Tax Id:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">{taxNb}</div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Alert Preference:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <select
                      name="alertPreference"
                      id="vehicle-select"
                      class="fmc-select"
                      value={alertPreference}
                      onInput={(e) => setalertPreference(e.target.value)}
                    >
                      <option label="Email" value="E">
                        Email
                      </option>
                      <option label="Both Email and Text" value="B">
                        Both Email and Text
                      </option>
                      <option label="Text via Phone" value="T">
                        Text via Phone
                      </option>
                    </select>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Email Addresess:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input name="field" value={email}onInput={(e) => setemail(e.target.value)}>
                    </fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Primary Contact:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input name="field" value={primaryContact}onInput={(e) => setprimaryContact(e.target.value)}></fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">
                    Secondary Contact:
                  </div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-input
                      name="field"
                      value={secondaryContact}onInput={(e) => setsecondaryContact(e.target.value)}
                    ></fmc-input>
                  </div>
                </div>
                <br />
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-4">Other:</div>
                  <div className="fds-layout-grid__cell--span-8">
                    <fmc-textarea
                      maxLength="255"
                      name="field"
                      onKeyDown={countCharacters}
                    ></fmc-textarea>
                    Remaining characters: {remainingChars} of 255
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="fds-layout-grid__cell--span-6">
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">
                  Primary Phone:
                </div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                          name="field"
                          limit="3n"
                          value={cityAreaCd}
                        ></fmc-input>
                      </div>
                      <div className="fds-layout-grid__cell--span-8">
                        <fmc-input
                          name="field"
                          value={phone}
                          maxLength="7"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">Fax Number:</div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          value={cityAreaCd1}
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                      <div className="fds-layout-grid__cell--span-8">
                        <fmc-input
                          name="field"
                          value={phone1}
                          maxLength="7"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">
                  After Hours Phone:
                </div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          value={cityAreaCd2}
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                      <div className="fds-layout-grid__cell--span-8">
                        <fmc-input
                          name="field"
                          value={phone2}
                          maxLength="7"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">
                  Mobile Phone:
                </div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          value={cityAreaCd3}
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                      <div className="fds-layout-grid__cell--span-8">
                        <fmc-input
                          name="field"
                          value={mobilePhone}
                          maxLength="7"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">
                  Primary Text Phone:
                </div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          value={cityAreaCd3}
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                      <div className="fds-layout-grid__cell--span-8">
                        <fmc-input
                          name="field"
                          value={primaryTextPhone}
                          maxLength="7"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="fds-layout-grid__inner p-0 ">
                <div className="fds-layout-grid__cell--span-4">
                  Additional Phones:
                </div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-8">
                        <select
                          slot="input"
                          name="select-field"
                          status="standard"
                          className="fmc-select"
                          required
                        >
                          <option label="" value=""></option>
                          <option label="Other Mobile Phone" value="">
                            Other Mobile Phone
                          </option>
                          <option label="Other Text Phone" value="">
                            Other Text Phone
                          </option>
                          <option label="Phone" value="">
                            Phone
                          </option>
                          <option label="Fax Phone" value="">
                            Fax Phone
                          </option>
                        </select>
                      </div>
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                  <fmc-input
                    name="field"
                    maxLength="7"
                    onKeyDown={handleKeyDown}
                  ></fmc-input>
                </div>
                <div className="fds-layout-grid__cell--span-4"></div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-8">
                        <select
                          slot="input"
                          name="select-field"
                          status="standard"
                          className="fmc-select"
                          required
                        >
                          <option label="" value=""></option>
                          <option label="Other Mobile Phone" value="">
                            Other Mobile Phone
                          </option>
                          <option label="Other Text Phone" value="">
                            Other Text Phone
                          </option>
                          <option label="Phone" value="">
                            Phone
                          </option>
                          <option label="Fax Phone" value="">
                            Fax Phone
                          </option>
                        </select>
                      </div>
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                  <fmc-input
                    name="field"
                    maxLength="7"
                    onKeyDown={handleKeyDown}
                  ></fmc-input>
                </div>
                <div className="fds-layout-grid__cell--span-4"></div>
                <div className="fds-layout-grid__cell--span-8">
                  <div className="fds-layout-gri p-0">
                    <div className="fds-layout-grid__inner p-0 ">
                      <div className="fds-layout-grid__cell--span-8">
                        <select
                          slot="input"
                          name="select-field"
                          status="standard"
                          className="fmc-select"
                          required
                        >
                          <option label="" value=""></option>
                          <option label="Other Mobile Phone" value="">
                            Other Mobile Phone
                          </option>
                          <option label="Other Text Phone" value="">
                            Other Text Phone
                          </option>
                          <option label="Phone" value="">
                            Phone
                          </option>
                          <option label="Fax Phone" value="">
                            Fax Phone
                          </option>
                        </select>
                      </div>
                      <div className="fds-layout-grid__cell--span-4">
                        <fmc-input
                          name="field"
                          maxLength="3"
                          onKeyDown={handleKeyDown}
                        ></fmc-input>
                      </div>
                    </div>
                  </div>
                  <fmc-input
                    name="field"
                    maxLength="7"
                    onKeyDown={handleKeyDown}
                  ></fmc-input>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        <div className="fds-layout-grid fmc-pa-3 dividerdiv">
          <div className="fds-layout-grid__inner">
            <div
              className="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom fmc-divider--top
    "
            >
              <div className="fds-weight--bold"></div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 "></div>
              </div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Effective Info:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {effectiveDate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Expires:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {expiryDate}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md fmc-divider--bottom
    "
            >
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 "></div>
              </div>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Insurance Info:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {effectiveDate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Expires:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {InsuranceInfoExpiryDt}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="fds-layout-gri p-0">
                <div className="fds-layout-grid__inner p-0 ">
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Business License:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {businessLcnText}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fds-layout-grid__cell--span-6">
                    <div className="fds-layout-gri p-0">
                      <div className="fds-layout-grid__inner p-0 ">
                        <div className="fds-layout-grid__cell--span-4">
                          Expires:
                        </div>
                        <div className="fds-layout-grid__cell--span-6">
                          {businessLcnExpiryDt}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {pricingArray?.length > 0 ? (
          <>
            {pricingArray.map((data) => {
              // Filter coverage data based on prcStrctTx
              const coverageData = coverageArray.filter(
                (coverage) => coverage.prcStrctTx === data.prcStrctTx
              );

              // Group county names by stAbbrCd
              const groupedCounties = coverageData.reduce((acc, coverage) => {
                if (!acc[coverage.stAbbrCd]) {
                  acc[coverage.stAbbrCd] = [];
                }
                acc[coverage.stAbbrCd].push(coverage.cntyNm);
                return acc;
              }, {});

              return (
                <>
                  {" "}
                  <div className="fds-layout-grid fmc-pa-3 dividerdiv">
                    <div className="fds-layout-grid__inner">
                      <div
                        className="
      fds-layout-grid__cell--span-12
      fmc-pb-3
      fmc-pb-5-md
      fmc-divider--bottom
    "
                      >
                        <div className="fds-weight--bold"></div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 "></div>
                          <p style={{ "text-align": "center" }}>
                            {" "}
                            Price structure Name :{data.prcStrctTx}
                          </p>
                        </div>

                        <p>FEE DETAILS</p>
                        <br></br>

                        <div className=""></div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">
                              <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                  <div className="fds-layout-grid__cell--span-4">
                                    Base Repo Fee: <p>(Self- Help)</p>
                                  </div>
                                  <div className="fds-layout-grid__cell--span-6">
                                    {"$" + data.feeAm}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fds-layout-grid__cell--span-6">
                              <input
                                type="checkbox"
                                checked={data.cntngntIn === "C" ? true : false}
                                name="checkbox-item-3"
                                value="item-3"
                                disabled=""
                              />{" "}
                              Contingency
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">
                              <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                  <div className="fds-layout-grid__cell--span-4">
                                    Base Repo Fee: <p>(Voluntary)</p>
                                  </div>
                                  <div className="fds-layout-grid__cell--span-6">
                                    {"$" + data.vlntrFeeAm}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="fds-layout-grid__cell--span-6">
                              <input
                                type="checkbox"
                                checked={
                                  data.dlvryToAuctIn === "D" ? true : false
                                }
                                name="checkbox-item-3"
                                value="item-3"
                                disabled=""
                              />
                              Delivery Auction
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">
                              <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                  <div className="fds-layout-grid__cell--span-4">
                                    Base Repo Fee: <p>(Dealer Voluntary)</p>
                                  </div>
                                  <div className="fds-layout-grid__cell--span-6">
                                    {"$" + data.vlntrTodlrFeeAm}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fds-layout-grid__cell--span-6">
                              GVW Indicator= {data.gvwTypeCd}
                            </div>
                          </div>
                        </div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 ">
                            <div className="fds-layout-grid__cell--span-6">
                              <div className="fds-layout-gri p-0">
                                <div className="fds-layout-grid__inner p-0 ">
                                  <div className="fds-layout-grid__cell--span-4">
                                    Base Repo Fee: <p>(Close Fee)</p>
                                  </div>
                                  <div className="fds-layout-grid__cell--span-6">
                                    {"$" + data.clsFeeAm}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md  fmc-divider--bottom
    "
                      >
                        <p className="center">COVERAGE AREA</p>
                        <div className="fds-weight--bold"></div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 "></div>
                          <div className="fds-layout-grid__cell--span-8">
                            {Object.entries(groupedCounties).map(
                              ([stAbbrCd, cntyNms]) => (
                                <div key={stAbbrCd}>
                                  {statesArray.map((item) => {
                                    if (item.stateabbrCode === stAbbrCd) {
                                      return (
                                        <p>
                                          <strong>{item.stateName}</strong>
                                        </p>
                                      );
                                    } else {
                                      return ""; // or return an empty string ''
                                    }
                                  })}
                                  <p>{cntyNms.join(", ")}</p>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <div className="fds-weight--bold"></div>
                        <div className="fds-layout-gri p-0">
                          <div className="fds-layout-grid__inner p-0 "></div>
                          <div className="fds-layout-grid__cell--span-8"></div>
                        </div>

                        <div
                          className="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md fmc-divider--top
    "
                        >
                          <div>
                            <p>
                              *By selecting Text via Phone or Both Email and
                              Text, you are agreeing to receive SMS text from
                              Ford Motor Credit Company. Message and data rates
                              may apply. Msg freq. varies.
                            </p>
                          </div>
                        </div>

                        <div
                          className="
      fds-layout-grid__cell--span-12
      fmc-pt-1-md fmc-divider--top
    "
                        ></div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          ""
        )}

        <section>
          <div className="center">
            <fmc-button label="Update" onClick={update}></fmc-button>
          </div>
        </section>
      </div>
    </>
  );
}

export default Profile;
